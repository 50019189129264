import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker'

import {normalizeNumberMaxDecimals, renderError} from "../../../../../../services/helpers";
import validator from "../../../../../../services/DataValidator";
import api from "../../../../../../api";
import { hideModal, showModal } from "../../../../../../actions/modals";
import Spiner from "../../../../../common/Spiner";
import {toCompanyList, toFuelList} from '../../../../../../helpers/toLists';

class RegisterCreateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                companyList: [],
                fuelList: [],
                typeRegisterObject: {},
            },
            price: '',
            company: '',
            comment: '',
            fuelType: '',
            date: new Date(),
            amount: '',
            closed: false,
            loan: false,
            callback: () => {},

            validation: []
        };
    }

    async componentDidMount(){

        const [companyList, fuelList] = await Promise.all([
            api.apiGetCompanyData({ extend: ['TypeRegister'] }),
            api.apiGetFuelList().then(toFuelList)
        ]);

        let typeRegisterObject = {};
        companyList.data.value.map((item, i) => {
            typeRegisterObject[item.ID] = item.TypeRegister.RegisterName
        });
        this.setState({
            data: {
                companyList: toCompanyList(companyList),
                fuelList,
                typeRegisterObject,
            },
            callback: this.props.propsData.callback
        });
    }

    handleCompany = (selectedOption) => {
        this.setState({
            company: selectedOption
        });
    };

    handleFuelType = (selectedOption) => {
        this.setState({
            fuelType: selectedOption
        });
    };

    handleChangeDate = date => this.setState({ date });

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(event.target.dataset.type === 'number'){
            value = normalizeNumberMaxDecimals(value, 9999999999999, 7);
        }

        this.setState({
            [name]: value
        });
    };

    handleSave = () => {

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'company', type: 'EMPTY', value: this.state.company },
            { id: 'date', name: 'date', type: 'EMPTY', value: this.state.date },
            { id: 'amount', name: 'amount', type: 'EMPTY', value: this.state.amount },
        ]);

        if(validationResult.state === 'success'){
            this.setState({ loading: true });
            let dataToSend = {
                CompanyID: +this.state.company.value,
                Amount: +this.state.amount,
                RegisterDate: this.state.date,
                Price: +this.state.price || null,
                Comment: this.state.comment || null,
                Closed: this.state.closed,
                Loan: this.state.loan,
                TypeFuelID: this.state.fuelType && this.state.fuelType.value ? this.state.fuelType.value : null
            };

            api.apiCreateRegister(dataToSend).then(res => {
                this.setState({ loading: false });
                if(Number(res.status) === 201){
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис у реєстрі', description: 'Запис у реєстрі був успішно створений', type: true })
                    this.state.callback();
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис у реєстрі', description: 'Запис у реєстрі не був створений. Зверніться до адміністратора', type: false })
                }
            }).catch(error => {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title: 'Створити запис у реєстрі', description: error.response.data.message, type: false });
            });
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){
        return (
            <div>
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">Створити запис у реєстрі</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        placeholder="Виберіть компанію"
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={this.state.data.companyList}
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Дата реєстрації</h5>
                                <div>
                                    <DateTimePicker
                                        disabled={true}
                                        locale="uk-UK"
                                        onChange={this.handleChangeDate}
                                        value={this.state.date}
                                    />
                                    <div className="form-group-error">
                                        { renderError('date', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кількість</h5>
                                <div className="form-group">
                                    <input
                                        placeholder="Введіть кількість"
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        data-type="number"

                                        value={this.state.amount}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('amount', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Тип обліку</h5>
                                <div className="form-group">
                                    <input
                                      placeholder="Виберіть компанію"
                                      value={this.state.data.typeRegisterObject[this.state.company.value]}
                                      disabled
                                      type="text"
                                      className="form-control"
                                      name="amount"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Вид палива</h5>
                                <div>
                                    <Select
                                      placeholder="Виберіть вид палива"
                                      value={this.state.fuelType}
                                      onChange={this.handleFuelType}
                                      options={this.state.data.fuelList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Ціна продажу, грн./л.</h5>
                                <div className="form-group">
                                    <input
                                      placeholder="Введіть ціну"
                                      type="text"
                                      className="form-control"
                                      name="price"
                                      data-type="number"

                                      value={this.state.price || ''}
                                      onChange={this.handleInputChange}
                                    />
                                    <div className="form-group-error">
                                        { renderError('price', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Заблоковано</h5>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="closed"

                                        value={this.state.closed}
                                        onChange={this.handleInputChange}
                                        checked={this.state.closed}
                                    />
                                    <div className="form-group-error">
                                        {renderError('closed', this.state.validation)}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Кредит</h5>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="loan"

                                        value={this.state.loan}
                                        onChange={this.handleInputChange}
                                        checked={this.state.loan}
                                    />
                                    <div className="form-group-error">
                                        {renderError('loan', this.state.validation)}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Коментар</h5>
                                <label>
                                    <textarea
                                        rows="3"
                                        placeholder="Залиште коментар"
                                        class="form-control"
                                        maxlength="100"
                                        name="comment"
                                        value={this.state.comment}
                                        onChange={this.handleInputChange}
                                    ></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                                disabled={this.state.loading}
                            >
                                {
                                    !this.state.loading
                                    ? <span>Зберегти</span>
                                    : <span>Зберігаємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        typeTerm: typeof state.user.data.typeTermList != "undefined" ? state.user.data.typeTermList: []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCreateModal);
