import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import {numberFormat} from "../../../../services/helpers";
import { selectDefTableSize } from '../../../../selectors/screenSelectors';
import createSelect from '../../../common/table/createSelect';
import NumberInput from '../../../common/table/NumberInput';
import DatePicker from '../../../common/table/DatePicker';

class Table extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        const { defaultPageSize } = this.props;
        return (
            <div className={`table-main-wrap mt-50 table-main-wrap_register ${!this.props.propsArraySort.length && this.props.propsGetDataStatus == 'pending' ? 'table-main-wrap_loading' : '' }`}>
                <div className="table-header">Інформація про рівнеміри</div>
                <ReactTable
                    defaultPageSize={defaultPageSize}
                    pageSize={this.props.pageSize}
                    pagepageSize={this.props.pageSize}
                    page={this.props.page}
                    onPageSizeChange={this.props.handleChangePageSize}
                    onPageChange={this.props.handleChangePage}
                    manual={true}
                    filterable
                    sortable
                    multiSort
                    onFilteredChange={this.props.handleOnFilterChange}
                    onSortedChange={this.props.handleOnSortChange}
                    filtered={this.props.filtered}
                    sorted={this.props.sorted}
                    pages={Math.ceil(this.props.count / this.props.pageSize)}
                    getTheadFilterThProps={(rowInfo) => {
                        return {
                            style: {
                                overflow: 'visible',
                                textAlign: 'left'
                            }
                        }
                    }}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: () => {
                                    this.props.propsRowClick({
                                        id: rowInfo.row._original.ID,
                                        index: rowInfo.index
                                    });
                                },
                                style: {
                                    backgroundColor: rowInfo.index === this.props.index ? '#B8B8B8' : 'white',
                                    color: rowInfo.index === this.props.index ? 'white' : 'black',
                                    cursor: 'pointer'
                                }
                            }
                        }else{
                            return {}
                        }
                    }}
                    data={this.props.propsArraySort}
                    columns={[
                        {   
                            Filter: createSelect({ options: this.props.data.fuelList, placeholder: 'Виберіть'}),
                            id: 'Barrel/TypeFuelID',
                            minWidth: 150,
                            Header: "Тип палива",
                            accessor: "Barrel.TypeFuel.FuelName",
                        },
                        {
                            Filter: DatePicker,
                            Header: "Дата створення",
                            className: "rightAlign",
                            id: "Created",
                            minWidth: 170,
                            accessor: item => {
                                return new Date(item.Created).toLocaleString()
                            }
                        },
                        {
                            Header: "Обсяг палива, л",
                            className: "rightAlign",
                            Filter: NumberInput,
                            id: "AdjustedBalance",
                            accessor: item => {
                                return item.AdjustedBalance ? numberFormat(+item.AdjustedBalance) : '-----------'
                            }
                        },
                    ]}
                    className=" -highlight"
                    NoDataComponent={() => null}
                    previousText="Назад"
                    nextText="Вперед"
                    rowsText="рядків"
                    pageText="Сторінка"
                    ofText="з"
                />
                <div className="loading" />
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        defaultPageSize: selectDefTableSize(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
       dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
