import React, { Component } from 'react';
import {renderError} from "../../../../services/helpers";
import validator from "../../../../services/DataValidator";
import api from "../../../../api";
import { hideModal, showModal } from "../../../../actions/modals";
import {connect} from "react-redux";
import DateTimePicker from "react-datetime-picker";
import Select from "../../../common/Select";
import contentDisposition from 'content-disposition'
import Spiner from "../../../common/Spiner";
import {toCompanyList, toStationList, toStationAdressesList, toCompaniesList} from "../../../../helpers/toLists";
import { th } from 'date-fns/locale';
let FileSaver = require('file-saver');

class AggregatedCardReport extends Component {

    constructor(props) {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        super(props);
        this.state = {
            loading: false,
            closed: false,
            company: { value: 0, label: 'Всі' },
            station:{ value: 0, label: 'Всі' },
            fromDate: new Date(date.setHours(0,0,0,0)),
            toDate: new Date(date.setHours(23,59,0,0)),
            maxDate: undefined,
            minDate: undefined,
            companyList: [],
            stationList: [],
            validation: [],
            callback: () => {},
        };
    }

    async componentDidMount() {
        this.setState({
            companyList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetCompanyList().then(toCompanyList)),
            stationList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetStationList().then(toStationList))
        })
    }

    getDaysCount = (date) => {
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
        return +lastDayOfMonth.getDate();
    }
    getMaxDate = (fromDate) => {
        if (!fromDate) return;
        const maxDate = new Date(fromDate);
        const daysCount = maxDate.getDate() === 1 ? this.getDaysCount(maxDate) : 30;
        maxDate.setDate(maxDate.getDate() + daysCount);
        return maxDate;
    }

    getMinDate = (toDate) => {
        if (!toDate) return;
        const minDate = new Date(toDate);
        const monthDaysCount = this.getDaysCount(minDate);
        const daysCount = monthDaysCount === minDate.getDate() ? monthDaysCount : 30;
        minDate.setDate(minDate.getDate() - daysCount);
        return minDate;
    }

    getToDate = (fromDate, maxDate) => {
        if(!fromDate) return '';
        if (this.state.toDate > maxDate) {
            const toDate = new Date(maxDate.toString());
            toDate.setDate(toDate.getDate() - 1);
            toDate.setHours(23);
            toDate.setMinutes(59);
            return toDate;
        }
        if (this.state.toDate < fromDate) {
            const toDate = new Date(fromDate.toString());
            toDate.setHours(23);
            toDate.setMinutes(59);
            return toDate;
        }
        return this.state.toDate;
    }

    getFromDate = (toDate, minDate) => {
        if (this.state.fromDate < minDate) {
            const fromDate = new Date(minDate.toString());
            fromDate.setDate(minDate.getDate() + 1);
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            return fromDate;
        }
        if (this.state.fromDate > toDate) {
            const fromDate = new Date(toDate.toString());
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            return fromDate;
        }
        return this.state.fromDate;
    }

    handleFromDate = fromDate => {
        const maxDate = this.getMaxDate(fromDate);
        this.setState({
            fromDate,
            toDate: this.getToDate(fromDate, maxDate),
            maxDate,
        });
    };

    handleToDate = toDate => {
        const minDate = this.getMinDate(toDate);
        this.setState({ 
            toDate,
            fromDate: this.getFromDate(toDate, minDate),
            minDate,
        });
    }

    handleCompany = async (selectedOption) => {
        if (selectedOption.value > 0) {
            let stationList = await api.apiGetStationByCompanyIDList(selectedOption.value).then(toStationAdressesList)
            if (stationList.find(x => x.value !== this.state.station.value))
                this.setState({
                    station: { value: 0, label: 'Всі' } 
            })
            this.setState({
                company: selectedOption,
                stationList: [{ value: 0, label: 'Всі' }].concat(stationList)
            })
        } else {
            this.setState({
                company:  selectedOption,
                station: { value: 0, label: 'Всі' },
                stationList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetStationList().then(toStationList))
            })
        }
    };

    handlerStation = async (selectedOption) => {
        if (selectedOption.value > 0)
        {
            this.setState({
                station: selectedOption,
                companyList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetCompaniesByAddressIDList(selectedOption.value).then(toCompaniesList))
            });
        } else {
            this.setState({
                station:  selectedOption,
                company: { value: 0, label: 'Всі' },
                companyList: [{ value: 0, label: 'Всі' }].concat(await api.apiGetCompanyList().then(toCompanyList))
            });
        }
    }

    handleSave = async () => {

        const { title, name } = this.props.propsData;

        let validationResult = validator.arrayValidation([
            { id: 'company', name: 'Компанія', type: 'EMPTY', value: this.state.company },
            { id: 'station', name: 'АЗС', type: 'EMPTY', value: this.state.station },
        ]);

        if(validationResult.state == 'success'){
            this.setState({ loading: true });
            let fromDate = (new Date(this.state.fromDate).toLocaleDateString("uk-UA")).split(',')[0];
            let toDate = (new Date(this.state.toDate).toLocaleDateString("uk-UA")).split(',')[0];

            fromDate = fromDate.split('.');
            toDate = toDate.split('.');

            let dataToSend = {
                company: this.state.company.value,
                station: this.state.station.value,
                fromDate: fromDate.indexOf('Invalid Date') === -1 ? `${fromDate[2]}-${fromDate[1]}-${fromDate[0]}` : null,
                toDate: toDate.indexOf('Invalid Date') === -1 ? `${toDate[2]}-${toDate[1]}-${toDate[0]}` : null,
                route: name
            };

            this.props.dispatchHideModal();
            this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" формується...', type: null })

            try {
                const res = await api.apiCreateReport(dataToSend);
                this.setState({ loading: false });
                const fileName = contentDisposition.parse(res.headers['content-disposition']).parameters.filename;

                let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, fileName);

                if(res.status == 200) {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" був успішно сформований.', type: true })
                } else {
                    this.props.dispatchHideModal();
                    this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
                }
            } catch (e) {
                this.setState({ loading: false });
                this.props.dispatchHideModal();
                this.props.dispatchShowModal('INFO_MODAL', { title, description: '"' + title + '" не був сформований. Зверніться до адміністратора', type: false })
            }
        }

        this.setState({
            validation: validationResult.errorArr
        });
    };

    render(){

        const { fromDate, toDate, companyList, stationList, isToDateDisabled } = this.state
        const { title } = this.props.propsData;
        return (
            <div className="noncom-modal">
                <div className="modal-header">
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h5 className="modal-title">{ title }</h5>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <h5>Компанія</h5>
                                <div>
                                    <Select
                                        value={this.state.company}
                                        onChange={this.handleCompany}
                                        options={companyList}
                                        placeholder="Виберіть компанію ..."
                                    />
                                    <div className="form-group-error">
                                        { renderError('company', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                            <div className="modal__row">
                                <h5>АЗС</h5>
                                <div>
                                    <Select
                                        value={this.state.station}
                                        onChange={this.handlerStation}
                                        options={stationList}
                                        placeholder="Виберіть АЗС ..."
                                    />
                                    <div className="form-group-error">
                                        { renderError('АЗС', this.state.validation) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <div className={"df ai-center"}>
                                    <h5>З</h5>
                                    <DateTimePicker
                                        locale="uk-UK"
                                        className="react-datetime-picker_margin-l-20"
                                        onChange={this.handleFromDate}
                                        value={fromDate}
                                        minDate={this.state.minDate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="modal__row">
                                <div className="df ai-center">
                                    <h5>До</h5>
                                    <DateTimePicker
                                        disabled={isToDateDisabled}
                                        locale="uk-UK"
                                        className="react-datetime-picker_margin-l-20"
                                        onChange={this.handleToDate}
                                        value={toDate}
                                        maxDate={this.state.maxDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-lg-12">
                            <button
                                disabled={this.state.loading}
                                onClick={this.handleSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                {
                                    !this.state.loading
                                        ? <span>Створити</span>
                                        : <span>Створюємо</span>
                                }
                                {!this.state.loading ? null : <Spiner />}
                            </button>
                            <button onClick={this.props.closeModal} type="button" className="btn btn-secondary">Відмінити</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchShowModal: (type, data) => dispatch(showModal(type, data)),
        dispatchHideModal: (type, data) => dispatch(hideModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatedCardReport);
