import React, { Component } from 'react';
import { connect } from 'react-redux'

import Sidebar from '../../Sidebar';
import RegisterTable from './items/RegisterTable';
import LoanTable from './items/LoanTable';
import Wrapper from '../../../index';
import api from '../../../../../api/index';
import { showModal } from "../../../../../actions/modals";
import {toPascalCase} from "../../../../../services/helpers";
import getCount from "../../../../../helpers/getCount";
import { toFuelList } from '../../../../../helpers/toLists';
import sortConverter from "../../../../../helpers/sortConverter";
import { stringConverter, booleanConverter, selectNumberConverter, numberConverter, createFilter, dateConverter, repaidLoanConverter } from "../../../../../helpers/filterConverters";
import { setFilterData } from '../../../../../actions/filtersReducers';

const convertersRegisterMap = {
    'Company/Name': stringConverter,
    RegisterDate: dateConverter,
    Amount: numberConverter,
    'Company/TypeRegisterID': selectNumberConverter,
    TypeFuelID: selectNumberConverter,
    Price: numberConverter,
    Closed: booleanConverter,
    Loan: booleanConverter,
    RegisterAmount: numberConverter,
    CoverageAmount: numberConverter,
    BalanceCoverageAmount: numberConverter,
    RepaidLoan: repaidLoanConverter,
}

const convertersLoanMap = {
    CoverageDate: dateConverter,
    Amount: numberConverter,
}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: {},
            registerFiltered: [],
            registerSorted: [{ id: 'RegisterDate', desc: true }],
            registerCount: 0,
            registerPageSize: 10,
            registerPage: 0,
            registerRowID: null,
            registerIndex: null,
            registerGetDataStatus: 'success',
            registerArraySort: [],
            loanFiltered: [],
            loanSorted: [],
            loanCount: 0,
            loanPageSize: 10,
            loanPage: 0,
            loanRowID: null,
            loanIndex: null,
            loanGetDataStatus: 'success',
            loanArraySort: [],
            isLoan: false,
            isClickLoanTable: false
        };
    }

    static loanFilterType = 'LOAN'
    static registerFilterType = 'REGISTER'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.loanFiltered, Index.loanFilterType));
        this.props.dispatch(setFilterData(this.state.registerFiltered, Index.registerFilterType));
    }

    async componentDidMount(){
        const fuelList = await api.apiGetFuelList().then(toFuelList);
        this.setState({
            loanFiltered: this.props.filtersReducer[Index.loanFilterType] || this.state.loanFiltered,
            registerFiltered: this.props.filtersReducer[Index.registerFilterType] || this.state.registerFiltered,
            data: {
                fuelList
            }
        }, this.handleRegisterSort)
    }

    handleRegisterChangePageSize = (registerPageSize) => {
        this.setState({ registerPage: 0, registerPageSize }, this.handleRegisterSort)
    }

    handleLoanChangePageSize = (loanPageSize) => {
        this.setState({ loanPage: 0, loanPageSize }, this.handleLoanSort)
    }

    handleRegisterChangePage = (registerPage) => {
        this.setState({ registerPage }, this.handleRegisterSort)
    }

    handleLoanChangePage = (loanPage) => {
        this.setState({ loanPage }, this.handleLoanSort)
    }

    handleRegisterSort = async () => {
        const data = {
            filter: createFilter(this.state.registerFiltered, convertersRegisterMap),
            order: sortConverter(this.state.registerSorted),
            top: this.state.registerPageSize,
            skip: this.state.registerPageSize * this.state.registerPage
        };

        this.setState({
            registerGetDataStatus: 'pending',
        });

        let res = await api.apiGetRegisterData(data);

        this.setState({
            registerGetDataStatus: 'success',
        });

        this.setState({
            registerCount: getCount(res),
            registerArraySort: toPascalCase(res.data.value),
            registerRowID: null,
            registerIndex: null,
            loanFiltered: [],
            loanSorted: [],
            loanCount: 0,
            loanPageSize: 10,
            loanPage: 0,
            loanRowID: null,
            loanIndex: null,
            loanGetDataStatus: 'success',
            loanArraySort: [],
            isLoan: false
        });
    };

    handleLoanSort = async () => {
        if (!this.state.isLoan) return
        const data = {
            filter: [
                `registerid eq ${this.state.registerRowID}`,
                ...createFilter(this.state.loanFiltered, convertersLoanMap),
            ],
            order: sortConverter(this.state.loanSorted),
            top: this.state.loanPageSize,
            skip: this.state.loanPageSize * this.state.loanPage
        };

        this.setState({
            loanGetDataStatus: 'pending',
        });

        let res = await api.apiGetLoanData(data);

        this.setState({
            loanCount: getCount(res),
            loanArraySort: toPascalCase(res.data.value),
            loanRowID: null,
            loanIndex: null,
            loanGetDataStatus: 'success',
        });

        if (!this.state.isClickLoanTable) return
        const dataRegister = {
            filter: createFilter(this.state.registerFiltered, convertersRegisterMap),
            order: sortConverter(this.state.registerSorted),
            top: this.state.registerPageSize,
            skip: this.state.registerPageSize * this.state.registerPage
        };

        this.setState({
            registerGetDataStatus: 'pending',
        });

        let resRegister = await api.apiGetRegisterData(dataRegister);

        this.setState({
            registerGetDataStatus: 'success',
        });

        this.setState({
            registerCount: getCount(res),
            registerArraySort: toPascalCase(resRegister.data.value),
        });
    };

    handleRegisterRowClick = (data) => {
        this.setState({
            registerRowID: data.id,
            registerIndex: data.index,
            loanRowID: null,
            loanIndex: null,
            isLoan: data.loan,
            isClickLoanTable: false
        }, this.handleLoanSort);
    };

    handleLoanRowClick = (data) => {
        this.setState({
            loanRowID: data.id,
            loanIndex: data.index,
            isClickLoanTable: true
        });
    };

    handleRegisterDelete = () => {
        let tmpObj = this.findRegisterObject();
        tmpObj.callback = this.handleRegisterSort;
        this.props.dispatchShowModal('REGISTER_DELETE_MODAL', tmpObj);
    };

    handleLoanDelete = () => {
        let tmpObj = this.findLoanObject();
        tmpObj.callback = this.handleLoanSort;
        this.props.dispatchShowModal('LOAN_DELETE_MODAL', tmpObj);
    };

    handleRegisterEdit = () => {
        let tmpObj = this.findRegisterObject();
        tmpObj.callback = this.handleRegisterSort;
        this.props.dispatchShowModal('REGISTER_EDIT_MODAL', tmpObj);
    };

    handleRegisterCreate = () => {
        let tmpObj = {};
        tmpObj.callback = () => {
            this.handleLoanSort();
            this.handleRegisterSort();
        };
        this.props.dispatchShowModal('REGISTER_CREATE_MODAL', tmpObj)
    };

    handleLoanCreate = () => {
        let tmpObj = {};
        tmpObj.callback = () => {
            this.handleLoanSort();
            this.handleRegisterSort();
        };
        tmpObj.registerId = this.state.registerRowID;
        this.props.dispatchShowModal('LOAN_CREATE_MODAL', tmpObj)
    };

    findRegisterObject = () => {
        const object = this.state.registerArraySort.find(({ ID }) => this.state.registerRowID === ID);
        if (object) return { ...object };
    };

    findLoanObject = () => {
        const object = this.state.loanArraySort.find(({ ID }) => this.state.loanRowID === ID);
        if (object) return { ...object };
    };

    handleOnRegisterFilterChange = (registerFiltered = []) => {
        this.setState({ registerFiltered }, this.handleRegisterSort)
    }

    handleOnLoanFilterChange = (loanFiltered = []) => {
        this.setState({ loanFiltered }, this.handleLoanSort)
    }

    handleOnRegisterSortChange = (registerSorted = []) => {
        this.setState(
            { registerSorted }, this.handleRegisterSort);
    }

    handleOnLoanSortChange = (loanSorted = []) => {
        this.setState(
            { loanSorted }, this.handleLoanSort);
    }

    handleClearFilter = async () => {
        this.setState({
            registerPage: 0,
            registerFiltered: []
        }, this.handleRegisterSort);
    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <RegisterTable
                                handleChangePageSize={this.handleRegisterChangePageSize}
                                handleChangePage={this.handleRegisterChangePage}
                                count={this.state.registerCount}
                                page={this.state.registerPage}
                                pageSize={this.state.registerPageSize}
                                propsRowID={this.state.registerRowID}
                                propsRowClick={this.handleRegisterRowClick}
                                propsArraySort={this.state.registerArraySort}
                                propsGetDataStatus={this.state.registerGetDataStatus}
                                index={this.state.registerIndex}
                                handleOnFilterChange={this.handleOnRegisterFilterChange}
                                handleOnSortChange={this.handleOnRegisterSortChange}
                                filtered={this.state.registerFiltered}
                                sorted={this.state.registerSorted}
                                data={this.state.data}
                            />
                            {
                                !this.state.isLoan ? null : (
                                    <LoanTable
                                        handleChangePageSize={this.handleLoanChangePageSize}
                                        handleChangePage={this.handleLoanChangePage}
                                        count={this.state.loanCount}
                                        page={this.state.loanPage}
                                        pageSize={this.state.loanPageSize}
                                        propsRowID={this.state.loanRowID}
                                        propsRowClick={this.handleLoanRowClick}
                                        propsArraySort={this.state.loanArraySort}
                                        propsGetDataStatus={this.state.loanGetDataStatus}
                                        index={this.state.loanIndex}
                                        handleOnFilterChange={this.handleOnLoanFilterChange}
                                        handleOnSortChange={this.handleOnLoanSortChange}
                                        filtered={this.state.loanFiltered}
                                        sorted={this.state.loanSorted}
                                        data={this.state.data}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <Sidebar
                        propsOnCreate={this.handleRegisterCreate}
                        propsOnEdit={this.handleRegisterEdit}
                        propsOnDelete={this.handleRegisterDelete}
                        propsOnLoanCreate={this.handleLoanCreate}
                        propsOnLoanDelete={this.handleLoanDelete}
                        propsDisableEdit={ this.state.registerRowID == null ? true : false }
                        propsDisableDelete={ this.state.registerRowID == null ? true : false }
                        propsDisableLoanCreate={ !this.state.isLoan }
                        propsDisableLoanDelete={ this.state.loanRowID == null ? true : false }
                        propsSelected={{register: true}}
                        showNavigation={true}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
