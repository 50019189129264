import React, { Component } from 'react';
import { connect } from 'react-redux'

import BarrelTable from './items/BarrelTable';
import AdjustedBalanceTable from './items/AdjustedBalanceTable';
import Wrapper from '../index';
import Sidebar from '../cod/Sidebar';
import Station from '../elements/Station';

import api from '../../../api/index';
import { showModal } from "../../../actions/modals";
import {toPascalCase} from "../../../services/helpers";
import getCount from "../../../helpers/getCount";
import {toFuelList} from "../../../helpers/toLists";
import sortConverter from "../../../helpers/sortConverter";
import { selectNumberConverter, numberConverter, createFilter, dateConverter } from "../../../helpers/filterConverters";
import { setFilterData } from '../../../actions/filtersReducers';

const barrelConvertersMap = {
    TypeFuelID: selectNumberConverter,
    Price: numberConverter,
    'BarrelBalance/BalanceAmountY': numberConverter,
    'BarrelBalance/BalanceAmountZ': numberConverter,
    'BarrelBalance.BalanceAmount': numberConverter,
    LowCapacity: numberConverter,
    WarningCapacity: numberConverter,
}

const adjustedBalanceConvertersMap = {
    'Barrel/TypeFuelID': selectNumberConverter,
    Price: numberConverter,
    Created: dateConverter,
    AdjustedBalance: numberConverter,
}

class Index extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: {
                fuelList: [],
            },
            barrel: false,
            barrelFiltered: [],
            barrelSorted: [],
            barrelCount: 0,
            barrelPageSize: 10,
            barrelPage: 0,
            barrelRowID: null,
            barrelIndex: null,
            barrelGetDataStatus: 'success',
            barrelArray: [],
            barrelArraySort: [],
            adjustedBalanceData: {
                fuelList: [],
            },
            adjustedBalance: true,
            adjustedBalanceFiltered: [],
            adjustedBalanceSorted: [],
            adjustedBalanceCount: 0,
            adjustedBalancePageSize: 10,
            adjustedBalancePage: 0,
            adjustedBalanceGetDataStatus: 'success',
            adjustedBalanceArray: [],
            adjustedBalanceArraySort: []
        };
    }

    static barrelFilterType = 'BARREL'
    static adjustedBalanceFilterType = 'ADJUSTED_BALANCE'

    componentWillUnmount() {
        this.props.dispatch(setFilterData(this.state.barrelFiltered, Index.barrelFilterType));
        this.props.dispatch(setFilterData(this.state.adjustedBalanceFiltered, Index.adjustedBalanceFilterType));
    }

    async componentDidMount(){
        const fuelList = await api.apiGetFuelList().then(toFuelList);
        this.setState({
            barrelFiltered: this.props.filtersReducer[Index.barrelFilterType] || this.state.barrelFiltered,
            data: {
                fuelList
            }
        }, () => {
            this.handleBarrelGetData();
            this.handleAdjustedBalanceGetData ();
        })
    }

    onBarrel = () => {
        this.setState({
            barrel: !this.state.barrel
        });
    }

    handleBarrelChangePageSize = (babelPageSize) => {
        this.setState({ barrelPge: 0, babelPageSize }, () => this.handleBarrelGetData())
    }

    handleBarrelChangePage = (barrelPage) => {
        this.setState({ barrelPage }, () => this.handleBarrelGetData())
    }

    handleOnBarrelFilterChange = (barrelFiltered = []) => {
        this.setState({ barrelFiltered }, this.handleBarrelGetData)
    }

    handleOnBarrelSortChange = (barrelSorted = []) => {
        this.setState(
            { barrelSorted },
            () => {
                this.handleBarrelGetData();
            }
        );
    }

    handleClearBarrelFilter = async () => {
        this.setState({
            barrelPage: 0,
            babelFiltered: []
        }, () => {
            this.handleBarrelGetData();
        });
    };

    handleBarrelGetData = async () => {
        this.setState({
            getBarrelDataStatus: 'pending'
        });
        const data = {
            filter: createFilter(this.state.barrelFiltered, barrelConvertersMap),
            order: sortConverter(this.state.barrelSorted),
            top: this.state.barrelPage,
            skip: this.state.babelPageSize * this.state.barrelPage
        };

        let res = await api.apiGetBarrelData(data);


        this.setState({
            rowID: null,
            index: null,
            barrelArray: toPascalCase(res.data.value),
            barrelArraySort: toPascalCase(res.data.value),
            getBarrelDataStatus: 'success',
            barrelCount: getCount(res)
        });

    };

    handleRowClick = (data) => {
        this.setState({
            rowID: data.id,
            index: data.index
        });
    };

    handleAdjustedBalanceRowClick = (data) => {
        this.setState({
            adjustedBalanceRowID: data.id,
            adjustedBalanceIndex: data.index
        });
    };

    handleEdit = () => {
        let tmpObj = this.findObject();
        tmpObj.callback = this.handleBarrelGetData;
        this.props.dispatchShowModal('BARREL_EDIT_MODAL', tmpObj);
    };

    findObject = () => {
        let tmpObj = {};
        for(let i = 0; i < this.state.barrelArray.length; i++){
            if(this.state.rowID == this.state.barrelArray[i].ID){
                tmpObj = Object.assign({}, this.state.barrelArray[i]);
            }
        }
        return tmpObj;
    };

    onAdjustedBalance = () => {
        this.setState({
            adjustedBalance: !this.state.adjustedBalance
        });
    }

    handleAdjustedBalanceChangePageSize = (adjustedBalancePageSize) => {
        this.setState({ adjustedBalancePage: 0, adjustedBalancePageSize }, () => this.handleAdjustedBalanceGetData ())
    }

    handleAdjustedBalanceChangePage = (adjustedBalancePage) => {
        this.setState({ adjustedBalancePage }, () => this.handleAdjustedBalanceGetData ())
    }

    handleOnAdjustedBalanceFilterChange = (adjustedBalanceFiltered = []) => {
        this.setState({ adjustedBalanceFiltered }, this.handleAdjustedBalanceGetData )
    }

    handleOnAdjustedBalanceSortChange = (adjustedBalanceSorted = []) => {
        this.setState(
            { adjustedBalanceSorted },
            () => {
                this.handleAdjustedBalanceGetData ();
            }
        );
    }

    handleClearAdjustedBalanceFilter = async () => {
        this.setState({
            adjustedBalancePage: 0,
            adjustedBalanceFiltered: []
        }, () => {
            this.handleAdjustedBalanceGetData();
        });
    };

    handleAdjustedBalanceGetData = async () => {
        this.setState({
            adjustedBalanceGetDataStatus: 'pending'
        });
        const data = {
            filter: createFilter(this.state.adjustedBalanceFiltered, adjustedBalanceConvertersMap),
            order: sortConverter(this.state.adjustedBalanceSorted),
            top: this.state.adjustedBalancePage,
            skip: this.state.adjustedBalancePageSize * this.state.adjustedBalancePage
        };

        let res = await api.apiGetAdjustedBalanceData(data);


        this.setState({
            adjustedBalanceRowID: null,
            adjustedBalanceIndex: null,
            adjustedBalanceArray: toPascalCase(res.data.value),
            adjustedBalanceArraySort: toPascalCase(res.data.value),
            adjustedBalanceGetDataStatus: 'success',
            adjustedBalanceCount: getCount(res)
        });

    };

    render(){
        return (
            <Wrapper>
                <div className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <Station />
                            <div className="col-lg-12">
                            <   div className={`${this.state.adjustedBalance ? 'active': ''} barrel`}>
                                    <div className="df filter-top" onClick={this.onAdjustedBalance}>
                                        <h3>Рівнеміри</h3>
                                        {
                                            this.state.adjustedBalance ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>
                                        }
                                    </div>
                                    <AdjustedBalanceTable
                                        handleChangePageSize={this.handleAdjustedBalanceChangePageSize}
                                        handleChangePage={this.handleAdjustedBalanceChangePage}
                                        count={this.state.adjustedBalanceCount}
                                        page={this.state.adjustedBalancePage}
                                        pageSize={this.state.adjustedBalancePageSize}
                                        propsArraySort={this.state.adjustedBalanceArraySort}
                                        propsGetDataStatus={this.state.adjustedBalanceGetDataStatus}
                                        handleOnFilterChange={this.handleOnAdjustedBalanceFilterChange}
                                        handleOnSortChange={this.handleOndjustedBalanceSortChange}
                                        filtered={this.state.adjustedBalanceFiltered}
                                        sorted={this.state.adjustedBalanceSorted}
                                        data={this.state.data}
                                        propsRowID={this.state.adjustedBalanceRowID}
                                        propsRowClick={this.handleAdjustedBalanceRowClick}
                                        index={this.state.adjustedBalanceIndex}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className={`${this.state.barrel ? 'active': ''} barrel`}>
                                    <div className="df filter-top" onClick={this.onBarrel}>
                                        <h3>Резервуари</h3>
                                        {
                                            this.state.barrel ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>
                                        }
                                    </div>
                                    <BarrelTable
                                        handleChangePageSize={this.handleBarrelChangePageSize}
                                        handleChangePage={this.handleBarrelChangePage}
                                        count={this.state.barrelCount}
                                        page={this.state.barrelPage}
                                        pageSize={this.state.barrelPageSize}
                                        propsRowID={this.state.rowID}
                                        propsRowClick={this.handleRowClick}
                                        propsArraySort={this.state.barrelArraySort}
                                        propsGetDataStatus={this.state.barrelGetDataStatus}
                                        index={this.state.index}
                                        handleOnFilterChange={this.handleOnBarrelFilterChange}
                                        handleOnSortChange={this.handleOnBarrelSortChange}
                                        filtered={this.state.babelFiltered}
                                        sorted={this.state.adjustedBalanceSorted}
                                        data={this.state.data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Sidebar
                        propsOnEdit={() => this.handleEdit()}
                        propsDisableEdit={ this.state.rowID == null ? true : false }
                        showNavigation={false}
                        onClear={this.handleClearFilter}
                    />
                </div>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filtersReducer: state.filtersReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchShowModal: (type, data) => dispatch(showModal(type, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
