import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toggleSidebar, closeSidebar } from "../../../actions";
import { selectIsMobile } from "../../../selectors/screenSelectors";

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    closeMenu = () => {
        if (this.props.isMobile) this.props.closeSidebar();
    };

    checkMenu = (e) => {
        if(
          !this.menuBlock ||
          !this.props.sidebar ||
          e.target === this.menuBlock ||
          this.menuBlock.contains(e.target)

        ) return;
        this.closeMenu();
    };
    onClear = () => {
        if (this.props.onClear) this.props.onClear();
        this.closeMenu();
    }

    propsOnCreate = () => {
        if (this.props.propsOnCreate) this.props.propsOnCreate();
        this.closeMenu();
    }

    propsOnEdit = () => {
        if (this.props.propsOnEdit) this.props.propsOnEdit();
        this.closeMenu();
    }

    propsOnDelete = () => {
        if (this.props.propsOnDelete) this.props.propsOnDelete();
        this.closeMenu();
    }

    propsOnLoanCreate = () => {
        if (this.props.propsOnLoanCreate) this.props.propsOnLoanCreate();
        this.closeMenu();
    }

    propsOnLoanDelete = () => {
        if (this.props.propsOnLoanDelete) this.props.propsOnLoanDelete();
        this.closeMenu();
    }

    propsOnSynchronization = () => {
        if (this.props.propsOnSynchronization) this.props.propsOnSynchronization();
        this.closeMenu();
    }

    createPush = (path) => () => {
        this.props.history.push(path);
        this.closeMenu();
    }

    componentDidMount(){
        document.addEventListener('click', this.checkMenu)
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.checkMenu)
    }

    render(){
        const { isMobile, showNavigation, showAdministration } = this.props;
        return (
          <section ref={ref => this.menuBlock = ref}>
              {
                  !isMobile || !showNavigation ? null : (
                    <div
                      onClick={() => this.props.toggleSidebar()}
                      className={`tool-bar__item tool-bar__item_hide tool-bar__button-mobile`}
                    >
                        {
                            this.props.sidebar ? <i className="fas fa-angle-double-left" /> : <i className="fas fa-angle-double-right" />
                        }
                    </div>
                  )
              }
              {
                  !isMobile || !showAdministration ? null : (
                    <div
                      onClick={() => this.props.toggleSidebar()}
                      className={`tool-bar__item tool-bar__item_hide tool-bar__button-mobile`}
                    >
                        {
                            this.props.sidebar ? <i className="fas fa-angle-double-left" /> : <i className="fas fa-angle-double-right" />
                        }
                    </div>
                  )
              }
              {
                  !isMobile ? null : (
                    <div className="tool-bar__mobile-crud">
                        {
                            this.props.onClear ?
                            <div onClick={this.onClear} className="tool-bar__item">
                                <i className="fas fa-ban" />
                            </div> : null
                        }
                        {
                            this.props.propsOnCreate ?
                            <div onClick={this.propsOnCreate} className="tool-bar__item">
                                <i className="fas fa-plus" />
                            </div> : null
                        }
                        {
                            this.props.propsOnEdit ?
                            <div onClick={this.propsOnEdit} className={`${this.props.propsDisableEdit ? 'disabled' : ''} tool-bar__item`}>
                                <i className="fas fa-edit" />
                            </div> : null
                        }
                        {
                            this.props.propsOnDelete ?
                            <div onClick={this.propsOnDelete} className={`${this.props.propsDisableDelete ? 'disabled' : ''} tool-bar__item`}>
                                <i className="fas fa-trash" />
                            </div> : null
                        }
                        {
                            this.props.propsOnLoanCreate ?
                                <li onClick={this.propsOnLoanCreate} className={`${this.props.propsDisableLoanCreate ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                    <i className="fas fa-loan-add" />
                                </li> : null
                        }
                        {
                            this.props.propsOnLoanDelete ?
                                <li onClick={this.propsOnLoanDelete} className={`${this.props.propsDisableLoanDelete ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                     <i className="fas fa-loan-remove" />
                                </li> : null
                        }
                        {
                            this.props.propsOnLoad && this.props.showLoadClassName ?
                                <div onClick={this.props.propsOnLoad} className="tool-bar__item">
                                    <i className={this.props.showLoadClassName} />
                                </div> : null
                        }
                        {
                            this.props.propsOnSynchronization ?
                                <li onClick={this.propsOnSynchronization} class={`${this.props.propsDisableSynchronization ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                    <i className="fas fa-loan-remove" />
                                </li> : null
                        }
                    </div>
                  )
              }
                <div className={`${this.props.sidebar ? 'active' : ''} tool-bar`}>
                    <ul className="tool-bar__list">
                        {
                            isMobile ? null : (
                              <li onClick={() => this.props.toggleSidebar()} className={`tool-bar__item tool-bar__item_hide`}>
                                  {
                                      this.props.sidebar ? <i className="fas fa-angle-double-left" /> : <i className="fas fa-angle-double-right" />
                                  }
                              </li>
                            )
                        }
                        {
                            this.props.onClear ?
                            <div onClick={this.onClear} className="tool-bar__item">
                                <i className="fas fa-ban" /><span>Очистити фільтр</span>
                            </div> : null
                        }
                        {
                            this.props.propsOnCreate && !isMobile ?
                                <li onClick={this.propsOnCreate} className="tool-bar__item">
                                    <i className="fas fa-plus" /><span>Створити</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnEdit && !isMobile ?
                                <li onClick={this.propsOnEdit} className={`${this.props.propsDisableEdit ? 'disabled' : ''} tool-bar__item`}>
                                    <i className="fas fa-edit" /><span>Редагувати</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnDelete && !isMobile ?
                                <li onClick={this.propsOnDelete} className={`${this.props.propsDisableDelete ? 'disabled' : ''} tool-bar__item`}>
                                    <i className="fas fa-trash" /><span>Видалити</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnLoanCreate && !isMobile ?
                                <li onClick={this.propsOnLoanCreate} className={`${this.props.propsDisableLoanCreate ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                    <i className="fas fa-loan-add" /><span>Додати погашення</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnLoanDelete && !isMobile ?
                                <li onClick={this.propsOnLoanDelete} className={`${this.props.propsDisableLoanDelete ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                     <i className="fas fa-loan-remove" /><span>Видалити погашення</span>
                                </li> : null
                        }
                        {
                                this.props.propsOnSynchronization && !isMobile ?
                                <li onClick={this.propsOnSynchronization} className={`${this.props.propsDisableSynchronization ? 'disabled' : ''} tool-bar__item tool-bar__item__loan`}>
                                        <i class="fas fa-sync" />
                                        <span>Синхронізація Uployal</span>
                                </li> : null
                        }
                        {
                            this.props.propsOnLoad && this.props.showLoadClassName ?
                                <li onClick={this.props.propsOnLoad} className={`tool-bar__item`}>
                                    <i className={this.props.showLoadClassName} /><span>Завантажити</span>
                                </li> : null
                        }
                        <li className="tool-bar__line" />
                    </ul>
                    {
                        this.props.showNavigation ?
                            <ul className="tool-bar__list">
                                { isMobile ? null : (
                                  <li className={`tool-bar__item cursor-none`}>
                                      <div className="tool-bar__item_line" />
                                  </li>
                                )}
                                <li onClick={this.createPush('/cod/card')} className={`${this.props.propsSelected.card ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-credit-card" />
                                    <span>Картка</span>
                                </li>
                                <li onClick={this.createPush('/cod/company')} className={`${this.props.propsSelected.company ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-building" />
                                    <span>Компанія</span>
                                </li>
                                <li onClick={this.createPush('/cod/department')} className={`${this.props.propsSelected.department ? 'active' : ''} tool-bar__item`}>
                                    <i className="far fa-building"/>
                                    <span>Відділ</span>
                                </li>
                                <li onClick={this.createPush('/cod/employee')} className={`${this.props.propsSelected.employee ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-user" />
                                    <span>Працівник</span>
                                </li>
                                <li onClick={this.createPush('/cod/register')} className={`${this.props.propsSelected.register ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-registered" />
                                    <span>Реєстри</span>
                                </li>
                                <li onClick={this.createPush('/cod/price')} className={`${this.props.propsSelected.price ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-hand-holding-usd" />
                                    <span>Ціна для компанії</span>
                                </li>
                                <li onClick={this.createPush('/cod/company-movement')} className={`${this.props.propsSelected.companyMovement ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-truck-loading" />
                                    <span>Рух по компанії</span>
                                </li>
                                <li onClick={this.createPush('/cod/register-audit')} className={`${this.props.propsSelected.registerAudit ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-cash-register" />
                                    <span>Аудит реєстра</span>
                                </li>
                                <li onClick={this.createPush('/cod/coupon')} className={`${this.props.propsSelected.coupon ? 'active' : ''} tool-bar__item`}>
                                    <i className="fas fa-qrcode" />
                                    <span>Талони</span>
                                </li>
                            </ul> : null
                    }
                    {
                        this.props.showAdministration ?
                            <ul className="tool-bar__list">
                                { isMobile ? null : (
                                  <li className={`tool-bar__item cursor-none`}>
                                      <div className="tool-bar__item_line" />
                                  </li>
                                )}
                                <li onClick={this.createPush('/owners')} className={`${this.props.propsSelected.ownerMovement ? 'active' : ''} tool-bar__item`}>
                                    <i class="fas fa-user-tie" />
                                    <span>Власники</span>
                                </li>
                                <li onClick={this.createPush('/stations')} className={`${this.props.propsSelected.stationsMovement ? 'active' : ''} tool-bar__item`}>
                                    <i class="fas fa-gas-pump" />
                                    <span>AЗС</span>
                                </li>
                                <li onClick={this.createPush('/administration')} className={`${this.props.propsSelected.usersMovement ? 'active' : ''} tool-bar__item`}>
                                    <i class="fas fa-users" />
                                    <span>Користувачі<br />PetrolBox.Client</span>
                                </li>
                            </ul> : null
                    }
                </div>
              </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.user.sidebar,
        isMobile: selectIsMobile(state),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: () => {
            dispatch(toggleSidebar())
        },
        closeSidebar: () => dispatch(closeSidebar()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
